<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="speedKey"
                :items="speedKeys"
                :item-text="(item) => item.speedkeyCode"
                :item-value="(item) => item.speedkeyCode"
                v-if="speedKeys.length > 0"
                label="Select Speed Key"
                :disabled="!displayImprestLineActions"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.fundNo"
                :items="funds"
                :item-text="(item) => item.no + ' - ' + item.name"
                :item-value="(item) => item.no"
                v-if="funds.length > 0"
                label="Select Fund"
                :disabled="!displayImprestLineActions"
              >
              </v-autocomplete>
            </v-col>
            <template v-if="hasProjectActivities">
              <v-col cols="6">
                <v-autocomplete
                  v-model="formData.shortcutDimension1Code"
                  :items="projectActivities"
                  :item-text="(item) => item.code + ' - ' + item.description"
                  :item-value="(item) => item.code"
                  v-if="dimension1.length > 0"
                  @input="filterDimension2($event)"
                  :label="`Select ${dimension1[0]['dimensionApi']['codeCaption']}`"
                  :disabled="!displayImprestLineActions"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="formData.shortcutDimension2Code"
                  :items="activities"
                  :item-text="
                    (item) => item.activitiesCode + ' - ' + item.description
                  "
                  :item-value="(item) => item.activitiesCode"
                  v-if="dimension2.length > 0"
                  :label="`Select ${dimension2[0]['dimensionApi']['codeCaption']}`"
                  :disabled="
                    !displayImprestLineActions ||
                    formData.shortcutDimension1Code === ''
                  "
                ></v-autocomplete>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="6">
                <v-autocomplete
                  v-model="formData.shortcutDimension1Code"
                  :items="dimension1"
                  :item-text="(item) => item.code + ' - ' + item.name"
                  :item-value="(item) => item.code"
                  v-if="dimension1.length > 0"
                  :label="`Select ${dimension1[0]['dimensionApi']['codeCaption']}`"
                  :disabled="!displayImprestLineActions"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="formData.shortcutDimension2Code"
                  :items="dimension2"
                  :item-text="(item) => item.code + ' - ' + item.name"
                  :item-value="(item) => item.code"
                  v-if="dimension2.length > 0"
                  :label="`Select ${dimension2[0]['dimensionApi']['codeCaption']}`"
                  :disabled="!displayImprestLineActions"
                ></v-autocomplete>
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.shortcutDimension3Code"
                :items="dimension3"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension3.length > 0"
                :label="`Select ${dimension3[0]['dimensionApi']['codeCaption']}`"
                :disabled="!displayImprestLineActions"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.shortcutDimension4Code"
                :items="dimension4"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension4.length > 0"
                :label="`Select ${dimension4[0]['dimensionApi']['codeCaption']}`"
                :disabled="!displayImprestLineActions"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.globalDimension5Code"
                :items="dimension5"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension5.length > 0"
                :label="`Select ${dimension5[0]['dimensionApi']['codeCaption']}`"
                :disabled="!displayImprestLineActions"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.globalDimension6Code"
                :items="dimension6"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension6.length > 0"
                :label="`Select ${dimension6[0]['dimensionApi']['codeCaption']}`"
                :disabled="!displayImprestLineActions"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.globalDimension7Code"
                :items="dimension7"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension7.length > 0"
                :label="`Select ${dimension7[0]['dimensionApi']['codeCaption']}`"
                :disabled="!displayImprestLineActions"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.globalDimension8Code"
                :items="dimension8"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension8.length > 0"
                :label="`Select ${dimension8[0]['dimensionApi']['codeCaption']}`"
                :disabled="!displayImprestLineActions"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ImprestMixin from "../ImprestMixin.js";

export default {
  name: "ImprestDimensionDialog",
  mixins: [ImprestMixin],
  props: {
    dimensionValues: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
    displayImprestLineActions: Boolean,
  },
  data() {
    return {
      activities: [],
      hasProjectActivities: false,
    };
  },
  mounted() {
    if (this.formData.shortcutDimension1Code !== "") {
      this.filterDimension2(this.formData.shortcutDimension1Code);
    }
  },
  computed: {
    speedKey: {
      get() {
        return this.formData.dimensionSpeedkeyCode;
      },
      set(val) {
        const speedKey = this.speedKeys
          .filter((el) => el.speedkeyCode === val)
          .shift();
        this.formData.dimensionSpeedkeyCode = val;
        this.formData.fundNo = speedKey.fundNo;
        this.formData.shortcutDimension1Code = speedKey.globalDimension1Code;
        this.formData.shortcutDimension2Code = speedKey.globalDimension2Code;
        this.formData.shortcutDimension3Code = speedKey.globalDimension3Code;
        this.formData.shortcutDimension4Code = speedKey.globalDimension4Code;
        this.formData.globalDimension5Code = speedKey.globalDimension5Code;
        this.formData.globalDimension6Code = speedKey.globalDimension6Code;
        this.formData.globalDimension7Code = speedKey.globalDimension7Code;
        this.formData.globalDimension8Code = speedKey.globalDimension8Code;
      },
    },
  },
  methods: {
    filterDimension2(code) {
      this.formData.shortcutDimension2Code = "";
      alert(code, this.formData.shortcutDimension2Code);
      this.activities = this.projectActivities.find(
        (e) => e.code === code
      ).projectActivities;
    },

    filterFromProjectActivities(code) {
      return this.projectActivities.find((e) => e.code === code)
        .projectActivities.length > 0
        ? true
        : false;
    },
  },
};
</script>
